import React, { Component } from "react";
import withStyles from '@mui/styles/withStyles';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import LanguageIcon from "@mui/icons-material/Language";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { withTranslation } from "react-i18next";

const changeColors = (idx, colors) => {
  colors = colors.map((i) => {
    let links = ["/my-orders", "/new-order", "/profile"];

    if (i === links.indexOf(window.location.pathname)) return "#FFFFFF";
    //"#333d45";
    else if (i === idx) return "#00000";
    //"#798c8b";
    //When not active but mouse pointed
    //"#a2aaa0";
    else return "#374048"; //"#333d45"; //"#00000"; //; //When not active and without mouse point
    /* orig colors:
    if (i === links.indexOf(window.location.pathname)) return "#d4d2d0";
    else if (i === idx) return "#a2aaa0";
    else return "#798c8b";
    */
  });

  return colors;
};

function Divider() {
  return <div style={{ width: "15px" }}> </div>;
}

const checkIfAuthorized = () =>
  localStorage.getItem("email") && localStorage.getItem("verification_code");

class NavBar extends Component {
  state = {
    authorized: checkIfAuthorized(),

    colors: changeColors(null, [0, 1, 2]),

    langAnchor: null,
    profileAnchor: null,

    screen_width: window.innerWidth, screen_height: window.innerHeight
  };

  updateDimensions = () => {
    this.setState({ screen_width: window.innerWidth, screen_height: window.innerHeight });
    // console.log("screen_width=", this.state.screen_width, "screen_height=", this.state.screen_height)
  };
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  openLangMenu = (event) => this.setState({ langAnchor: event.currentTarget });
  openProfileMenu = (event) =>
    this.setState({
      profileAnchor: event.currentTarget,
      authorized: checkIfAuthorized(),
    });

  signOut = () => {
    // localStorage.clear();
    // window.localStorage.removeItem("email");
    window.localStorage.removeItem("verification_code");
    window.location = "/sign-in";
  };

  render() {
    const { classes, t, i18n } = this.props;
    let colors = [0, 1, 2];
    // let colors = ["#333d45", "#133d45", "#233d45"];

    const chooseLang = (lang) => {
      if (lang) i18n.changeLanguage(lang);

      this.setState({ langAnchor: null });
    };

    const flagSrcs = {
      fin: "https://th.bing.com/th/id/OIP.9RGAqKboAptXgVI0EZTuZQHaEh?w=314&h=192&c=7&o=5&pid=1.7",
      eng: "https://th.bing.com/th/id/R2dac0613b0d936029f37feb224cdb4dd?rik=gemwNkp4ys2GMA&riu=http%3a%2f%2fimages.wikia.com%2fmortalengines%2fimages%2fb%2fb6%2fEnglish_flag.png&ehk=ryExDJQpL2TfSVFp8k4qmam3FaxY8Jg2upGBkOJ8aP8%3d&risl=&pid=ImgRaw",
    };

    return (
      <div className={classes.grow}>
        <AppBar
          position="static"
          style={{ backgroundColor: "#8fa39c" /*"#333"*/ }}
        >
          <Toolbar>
            <a href="https://www.zentuvo.fi/">
              <img
                src="https://zentuvo.fi/app/uploads/2021/08/Logo-zentuvo_dark_oneline.svg"
                alt="Zentuvo Logoty"
                height="24"
                sw={this.state.screen_width} 
                sw1={this.state.screen_width/8} 
                sw1s={(this.state.screen_width/8).toString()} 
                width={(this.state.screen_width<1000)?(this.state.screen_width/8).toString():"120"} 
                className={classes.logo}
              />
            </a>
            {/* <span>&nbsp;&nbsp;&nbsp;</span>
            <a href="https://www.etuovi.com/">
              <img
                src="https://zupload.s3-eu-west-1.amazonaws.com/images/Etuovi-logo.svg"
                alt="Zentuvo Logoty"
                height="24"
                sw={this.state.screen_width} 
                sw1={this.state.screen_width/8} 
                sw1s={(this.state.screen_width/8).toString()} 
                width={(this.state.screen_width<1000)?(this.state.screen_width/8).toString():"120"} 
                className={classes.logo}
              />
            </a> */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "auto",
              }}
            >
              <div
                style={{ cursor: "pointer" }}
                onMouseOver={() =>
                  this.setState({ colors: changeColors(0, colors) })
                }
                onMouseOut={() =>
                  this.setState({ colors: changeColors(null, colors) })
                }
                onClick={() => (window.location = "/my-orders")}
              >
                <span style={{ color: this.state.colors[0] }}>
                  {t("MY ORDERS")}
                </span>
              </div>

              <Divider />

              <div
                style={{ cursor: "pointer" }}
                onMouseOver={() =>
                  this.setState({ colors: changeColors(1, colors) })
                }
                onMouseOut={() =>
                  this.setState({ colors: changeColors(null, colors) })
                }
                onClick={() => (window.location = "/new-order")}
              >
                <span style={{ color: this.state.colors[1] }}>
                  {t("NEW ORDER")}
                </span>
              </div>

              <Divider />
            </div>

            <div style={{ display: "flex" }}>
              <div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={this.openLangMenu}
                  aria-controls="lang-menu"
                  aria-haspopup="true"
                >
                  <span style={{ color: "#d4d2d0" }}>
                    <LanguageIcon />
                  </span>
                </div>

                <Menu
                  id="lang-menu"
                  aria-controls="lang-menu"
                  keepMounted
                  anchorEl={this.state.langAnchor}
                  open={Boolean(this.state.langAnchor)}
                  onClose={() => chooseLang(null)}
                >
                  <MenuItem onClick={() => chooseLang("fi")}>
                    <img src={flagSrcs.fin} width="25px" alt="Lng Finnish" />{" "}
                    &nbsp; {t("FINNISH")}
                  </MenuItem>

                  <MenuItem onClick={() => chooseLang("en")}>
                    <img src={flagSrcs.eng} width="25px" alt="Lng English" />{" "}
                    &nbsp; {t("ENGLISH")}
                  </MenuItem>
                </Menu>
              </div>

              <Divider />

              <div
                style={{ cursor: "pointer" }}
                onMouseOver={() =>
                  this.setState({ colors: changeColors(2, colors) })
                }
                onMouseOut={() =>
                  this.setState({ colors: changeColors(null, colors) })
                }
              >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={this.openProfileMenu}
                  aria-controls="profile-menu"
                  aria-haspopup="true"
                >
                  <span style={{ color: this.state.colors[2] }}>
                    <AccountCircleIcon />
                  </span>
                </div>

                <Menu
                  id="profile-menu"
                  aria-controls="profile-menu"
                  keepMounted
                  anchorEl={this.state.profileAnchor}
                  open={Boolean(this.state.profileAnchor)}
                  onClose={() => this.setState({ profileAnchor: null })}
                >
                  <MenuItem
                    onClick={() => (window.location = "/profile")}
                    style={{ display: this.state.authorized ? "" : "none" }}
                    hover
                  >
                    {t("Profile")}
                  </MenuItem>

                  <MenuItem
                    onClick={() => this.signOut()}
                    style={{ display: this.state.authorized ? "" : "none" }}
                    hover
                  >
                    {t("Sign-Out")}
                  </MenuItem>

                  <MenuItem
                    onClick={() => (window.location = "/sign-in")}
                    style={{ display: this.state.authorized ? "none" : "" }}
                    hover
                  >
                    {t("Sign-In")}
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const useStyles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
});

export default withTranslation()(withStyles(useStyles)(NavBar));
